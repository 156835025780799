<template>
  <router-view />
</template>

<script>
import { getHashQuery } from './utils/request';
import VConsole from 'vconsole';
export default {
  name: 'App',
  mounted() {
    const hashQuery = getHashQuery();
    if (hashQuery.console) {
      new VConsole();
    }
  }
}
</script>
