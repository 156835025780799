import { createRouter, createWebHashHistory } from 'vue-router'

const routerHistory = createWebHashHistory()

import { defineAsyncComponent } from 'vue'
import "../assets/font/iconfont.css"

// 定义路由
const routes = [
    {
        path: '/',
        component: defineAsyncComponent(() => import('../layout/index.vue')),
        redirect: { name: 'ai-video' },
        children: [
            {
                path: 'ai-video',
                name: 'ai-video',
                component: defineAsyncComponent(() => import('../view/video-list.vue')),
            },
            {
                path: 'video/sentencesearch',
                name: 'video/sentencesearch',
                component: defineAsyncComponent(() => import('../view/sentencesearch.vue')),
            },
            {
                path: 'video/lastrecord',
                name: 'video/lastrecord',
                component: defineAsyncComponent(() => import('../view/lastrecord.vue')),
            },
            {
                path: 'mind/:word?',
                name: 'mind',
                component: defineAsyncComponent(() => import('../view/mind.vue')),
            },

        ]
    },
    {
        path: '/ai-video-detail',
        name: 'ai-video-detail',
        component: defineAsyncComponent(() => import('../layout/videoDetailLayout.vue')),
        children: [{
            path: 'theater',
            name: 'video-theater',
            component: defineAsyncComponent(() => import('../viewV2/video-theater.vue'))
        }]
    },
    {
        path: '/page',
        name: 'page',
        component: defineAsyncComponent(() => import('../view/page.vue')),
        meta: { title: 'WordMap.net' }
    },
    {
        path: '/ai-video/:pageMode/:childPageMode?',
        name: 'video-detail',
        component: defineAsyncComponent(() => import('../view/video-theater.vue')),
        beforeEnter: (to, from, next) => {
            if (to.query.video_id) localStorage.setItem('curVideoId', to.query.video_id);
            if (!to.query.video_id) to.query.video_id = localStorage.getItem('curVideoId');
            next()
        },
        meta: { title: 'WordMap.net' }
    },
    {
        path: '/login',
        name: 'login',
        component: defineAsyncComponent(() => import('../view/login.vue')),
        meta: { title: 'WordMap.net' }
    },
    {
        path: "/chatninja/:token",
        name: "tokenLogin",
        component: defineAsyncComponent(() => import('../view/tokenLogin.vue')),
        meta: { title: "WordMap.net" },
    }
]

// 创建路由器
const router = createRouter({
    history: routerHistory,
    routes: routes
})

export default router